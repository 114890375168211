import React, { useState, useRef, useEffect } from "react"
import "../styles/about-us.less"
import Header from "../components/header"
import SEO from "../components/SEO"
import Footer from "../components/footer"
import { Button } from "../components/ui"
import { Feedback, Solution } from "./products/bare-metal-cloud"
import { navigate, graphql } from "gatsby"
import { sort } from "ramda"
// import
import Drop from "../components/drop"
const loadText = require("src/utils").loadText
const static_data = loadText("about-us")
const urlData = loadText("url")
import { lang } from "../utils"
// console.log(urlData)
const handleUrlInStr = (str: string) => {
  return str.replace(/\{\w+\}/g, (key: string) => {
    // console.log(arguments)
    return `"${urlData[key.slice(1, -1)].link}"`
  })
  // console.log(v)
  // return str
}
const macy = typeof window !== "undefined" ? require("macy") : function () {}
const Top = () => (
  <div className="aboutUs-top-container">
    <div className="aboutUs-top">
      <Header logo="/logo-blue.png" />

      {/* <div className="aboutUs-topContentWrapper"> */}
      <div className="aboutUs-topContent">
        <div className="aboutUs-topContentRow">
          <div>
            <h1 className="title font-56 semi-bold t-color">
              {static_data.topBanner.title}
            </h1>
            <div className="subtitle mt-24 font-20">
              {static_data.topBanner.subtitle}
            </div>
            <div
              className="description mt-32 font-20 t-color"
              style={{ maxWidth: "500px", lineHeight: "32px", fontWeight: 400 }}
            >
              {static_data.topBanner.description}
            </div>
          </div>
          <div>
            <video autoPlay={true} muted controls>
              <source src={`/about-us-${lang}.mp4`} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
    {/* </div> */}
  </div>
)
const VisionAndMission = () => (
  <div className="aboutUs-visionAndMission">
    <div className="only-desktop">
      {/* <Drop
        color="rgb(198,236,255)"
        bgColor="#fff"
        style={{ top: "0" }}
        size="xl"
      /> */}
    </div>
    <div className="aboutUs-visionAndMission-content full-bg  t-color">
      <div>
        <div className="title font-36 semi-bold">
          {static_data.vision.title}
        </div>
        <div className="description font-20 mt-24">
          {static_data.vision.description}
        </div>
      </div>
      <div>
        <div className="title font-36 semi-bold">
          {static_data.mission.title}
        </div>
        <div className="description font-20 mt-24">
          {static_data.mission.description}
        </div>
      </div>
    </div>
  </div>
)
const Wow = () => (
  <div className="aboutUs-wow">
    <div className="aboutUs-wow-top" />
    <div className="aboutUs-wow-top" />
    <div className="aboutUs-wow-bg">
      <div className="aboutUs-wow-content">
        <div className="font-36 semi-bold">{static_data.wow.title}</div>
        <div
          className="font-20 mt-24 block-middle"
          style={{ maxWidth: "778px" }}
          dangerouslySetInnerHTML={{ __html: static_data.wow.description }}
        />
      </div>
    </div>
    {/* <Drop
      color="transparent"
      bgColor="rgb('0,123,224')"
      style={{ top: "0", position: "absolute" }}
      size="l"
    /> */}
  </div>
)
const People = () => (
  <div className="aboutUs-people full-bg cover-1920">
    {/* <img src="/about-us/drop.svg" alt="drop" className="drop" /> */}
    {/* <Drop bgColor="transparent" color="#fff" style={{ top: "0" }} size="l" /> */}
  </div>
)
const History = ({ data }) => {
  const [collapsed, setC] = useState(false)
  return (
    <div className={`aboutUs-history ${collapsed ? "collapsed" : ""}`}>
      <div className="font-36 semi-bold t-color">
        {static_data.history.title}
      </div>
      <div className="aboutUs-history-mask"></div>
      <div style={{ position: "relative" }}>
        {/* <div
          className="aboutUs-history-open aboutUs-history-button font-20"
          onClick={() => setC(false)}
        >
          {static_data.history.viewAll}
        </div>
        <div
          className="aboutUs-history-close aboutUs-history-button font-20"
          onClick={() => {
            console.log(
              window.pageYOffset || document.documentElement.scrollTop
            )
            setC(true)
          }}
        >
          {static_data.history.collapse}
        </div> */}
        <div className="aboutUs-history-container  mt-64">
          <div className="page-middle">
            <div className="aboutUs-history-itemContainer">
              <img src="/about-us/drop.svg" alt="drop" className="drop" />
              {data.map(({ year, pictures, content }, idx) => (
                <div key={idx} className="aboutUs-history-item clearfix">
                  <div className="left">
                    <div className="year font-36 semi-bold">{year}</div>
                    <div className="mt-32">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.replace(
                            "https://www.zenlayer.com/",
                            "/"
                          ),
                        }}
                      />
                      {/* {events.map(e => (
                    <div className="event font-16">{e}</div>
                  ))} */}
                    </div>
                  </div>
                  <div className="right">
                    <div className="right-flex">
                      {pictures.map(({ localFile, alternativeText }, i) => (
                        <img
                          key={i}
                          src={localFile.publicURL}
                          alt={alternativeText}
                        ></img>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const Certification = ({ data }) => {
  const container = useRef()
  useEffect(() => {
    new macy({
      container: container.current,
      columns: 3,
      margin: 24,
      breakAt: {
        1250: {
          columns: 2,
        },
        950: {
          columns: 1,
        },
      },
    })
  }, [])
  return (
    <div className="aboutUs-certification">
      <div className="font-36 semi-bold t-color">
        {static_data.certification.title}
      </div>
      <div className="aboutUs-certification-itemContainer page-middle ">
        <div
          ref={container}
          style={{ maxWidth: "1224px" }}
          className="block-middle"
        >
          {data.map(({ name, picture }, idx) => (
            <div key={idx}>
              <div className="aboutUs-certification-item">
                {/* <div className="pic"> */}
                <img
                  src={picture.localFile.publicURL}
                  alt={picture.alternativeText}
                />
                {/* </div> */}
                <div className="font-20 t-color semi-bold name block-middle">
                  {name}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
const Future = () => (
  <div className="aboutUs-future">
    <div className="font-36 semi-bold t-color">{static_data.future.title}</div>
    <div className="aboutUs-future-itemWrap">
      {["team", "partner"].map(key => (
        <div className="aboutUs-future-item" key={key}>
          <div className="imgContainer">
            <img src={`/about-us/future-${key}.png`} alt={key} />
          </div>
          <div className="textContainer">
            <div className="font-20 t-color">
              {static_data.future[key].title}
            </div>
            <div
              className="mt-24 content t-color"
              dangerouslySetInnerHTML={{
                __html: handleUrlInStr(static_data.future[key].content),
              }}
            />
            {/* <div className="mt-32">
              <Button
                size="l"
                onClick={() => {
                  navigate(urlData[static_data.future[key].linkKey].link)
                }}
              >
                {static_data.future[key].buttonText}
              </Button>
            </div> */}
          </div>
        </div>
      ))}
    </div>
  </div>
)
export const OurValue = ({ style = {} }) => {
  const [active, setActive] = useState()
  return (
    <div className="aboutUs-value" style={style}>
      <div className="font-36 semi-bold t-color">{static_data.value.title}</div>
      <div className="aboutUs-value-container page-middle only-desktop">
        {static_data.value.content.map(({ color, name, value }, i) => (
          <div
            key={i}
            className={`aboutUs-value-item ${i === active ? "active" : ""}`}
            onMouseEnter={() => {
              setActive(i)
            }}
          >
            <div className="aboutUs-value-itemContent">
              <div style={{ background: color }}>
                <div
                  className="item-normal"
                  style={{
                    backgroundImage: `url("/about-us/value-${i + 1}.svg")`,
                    height: "100%",
                  }}
                >
                  <div
                    className="font-36 semi-bold"
                    style={{ padding: "0 32px" }}
                  >
                    {name}
                  </div>
                </div>
              </div>
              <div
                className="item-active"
                style={{
                  backgroundImage: `url("/about-us/value-${i + 1}-hover.svg")`,
                  backgroundPosition: "top",
                }}
              >
                <div
                  className="font-36 semi-bold t-color block-middle"
                  style={{ maxWidth: "312px" }}
                >
                  {name}
                </div>
                <div
                  className="mt-24 font-20 block-middle hoverContent"
                  style={{ maxWidth: "288px" }}
                >
                  {value}
                </div>
              </div>
            </div>
            {/* <div>{value}</div> */}
          </div>
        ))}
      </div>
      <div className="aboutUs-value-container only-mobile">
        {static_data.value.content.map(({ color, name, value }, i) => (
          <div key={i} className="aboutUs-value-item-m">
            <div className="aboutUs-value-itemContent">
              <img src={`/about-us/value-${i + 1}.svg`} alt="" />
              <div className="font-36 semi-bold t-color block-middle">
                {name}
              </div>
              <div className="mt-40 font-20 block-middle">{value}</div>
            </div>
            <div
              className="borderBottom"
              style={{ backgroundColor: color }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default function Home({ data }) {
  const {
    strapiTestimonials,
    allStrapiCompanyHistories,
    allStrapiCertifications,
  } = data

  return (
    <div className="aboutUs">
      <SEO {...static_data.seo} />
      <Top />
      <VisionAndMission />
      <People />
      {/* <Wow /> */}
      <OurValue />
      <Feedback {...strapiTestimonials} />
      {/* <Solution {...static_data.solution} /> */}
      {/* <History
        data={sort((a, b) => a.year - b.year, allStrapiCompanyHistories.nodes)}
      /> */}
      {/* <Certification data={allStrapiCertifications.nodes} /> */}
      <Future />
      <Footer />
    </div>
  )
}
export const query = graphql`
  {
    allStrapiCompanyHistories {
      nodes {
        pictures {
          localFile {
            publicURL
          }
          alternativeText
        }
        year
        content
      }
    }
    allStrapiCertifications {
      nodes {
        name
        picture {
          localFile {
            publicURL
          }
          alternativeText
        }
      }
    }
    strapiTestimonials(inPage: { eq: "aboutUs" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
